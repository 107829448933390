import {
  Box,
  Container,
  Stack,
  Text,
  Link,
  useColorModeValue,
  SimpleGrid,
} from '@chakra-ui/react';

export default function Footer() {
  const today = new Date();

  const currentYear = today.getFullYear();

  return (
    <Box
      bg={useColorModeValue('gray.50', 'gray.900')}
      color={useColorModeValue('gray.700', 'gray.200')}
    >
      <Container
        as={Stack}
        maxW={'6xl'}
        py={4}
        pb={{ base: 6, md: 4 }}
        direction={{ base: 'column', md: 'row' }}
        spacing={4}
        justify={{ base: 'center', md: 'space-between' }}
        align={{ base: 'center', md: 'center' }}
        fontSize={{ base: '14px' }}
      >
        <Text>
          © {currentYear} <a href="/">HowLongism</a>. All rights reserved.
        </Text>
        <Stack
          direction={{ md: 'row', base: 'column' }}
          spacing={{ md: 6, base: 2 }}
          align={{ base: 'center', md: 'center' }}
        >
          <Link href={'/'}>Home</Link>
          <Link href={'/terms-and-conditions'}>Terms & Conditions </Link>
          <Link href={'/privacy-policy'}>Privacy Policy</Link>
          <Link href={'/about'}>About</Link>
          <Link href={'/contact'}>Contact</Link>
        </Stack>
      </Container>
    </Box>
  );
}
