import { ReactNode, useEffect } from 'react';
import {
  Box,
  Flex,
  Avatar,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  useDisclosure,
  useColorModeValue,
  Stack,
  useColorMode,
  Center,
  Container,
  Text,
  DarkMode,
} from '@chakra-ui/react';
import { MoonIcon, SunIcon } from '@chakra-ui/icons';
import { Link } from 'react-router-dom';

const NavLink = ({ children }) => (
  <Link
    px={2}
    py={1}
    rounded={'md'}
    _hover={{
      textDecoration: 'none',
      bg: useColorModeValue('gray.200', 'gray.700'),
    }}
    href={'#'}
  >
    {children}
  </Link>
);

export default function Nav() {
  const { colorMode, toggleColorMode } = useColorMode('dark');
  const { isOpen, onOpen, onClose } = useDisclosure();
  useEffect(() => {
    localStorage.setItem('chakra-ui-color-mode', 'dark');
  }, [colorMode]);

  return (
    <>
      <Box bg={useColorModeValue('gray.50', 'gray.900')} px={4}>
        <Container maxW="6xl">
          <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
            <Link to="/">
              <Box cursor={'pointer'} color="green.400">
                <strong>HowLongism</strong>
              </Box>
            </Link>

            <Flex alignItems={'center'}>
              <Stack direction={'row'} spacing={7}>
                <Button onClick={toggleColorMode}>
                  {colorMode === 'light' ? <SunIcon /> : <MoonIcon />}
                </Button>
              </Stack>
            </Flex>
          </Flex>
        </Container>
      </Box>
      {/* <Box bg={useColorModeValue('gray.100', 'gray.900')} px={4}>
        <Container maxW="6xl">
          <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
            <Link to="/">
              <Box cursor={'pointer'}>Logo</Box>
            </Link>

            <Flex alignItems={'center'}>
              <Stack direction={'row'} spacing={7}>
                <Button onClick={toggleColorMode}>
                  {colorMode === 'dark' ? <MoonIcon /> : <SunIcon />}
                </Button>
              </Stack>
            </Flex>
          </Flex>
        </Container>
      </Box> */}
    </>
  );
}
