import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Container, Stack, Text, Box, Heading, Link } from '@chakra-ui/react';
import React from 'react';
// import { Link } from 'react-router-dom';
import Footer from '../components/footer';
import Meta from '../components/Meta';
import Nav from '../components/navbar';

export const ContactEmail = () => {
  return (
    <>
      <Nav />
      <Meta
        pageTitle="Contact"
        pageDescription="Feel free to get in touch with us."
      />

      <Container maxW={'6xl'} minH={'96vh'}>
        <Stack
          as={Box}
          spacing={{ base: 10, md: 14 }}
          py={{ base: 10, md: 20 }}
        >
          <Heading
            as="h1"
            color={'green.500'}
            textAlign={'center'}
            fontWeight={600}
            fontSize={{ base: '3xl', sm: '4xl', md: '6xl' }}
            id="page-description"
          >
            Contact Us
          </Heading>
        </Stack>
        <Stack
          spacing={{ base: 4, md: 6 }}
          textAlign="center"
          fontSize={{ base: '16px', sm: '16px', md: '18px' }}
        >
          <Text>
            Feel free to reach out to us at{' '}
            <Link href="mailto:contact@howlongism.com" isExternal>
              contact@howlongism.com
            </Link>{' '}
            for advertisements, promotions, queries or improvements to our site.
          </Text>
        </Stack>
      </Container>
      <Footer />
    </>
  );
};
