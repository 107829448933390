import {
  Container,
  Skeleton,
  SkeletonCircle,
  SkeletonText,
  Heading,
  Text,
  Stack,
} from '@chakra-ui/react';
export default function Simmer() {
  return (
    <Container maxW="6xl">
      <Stack
        textAlign={'center'}
        justifyContent={'center'}
        alignItems={'center'}
        spacing={{ base: 10, md: 14 }}
        py={{ base: 10, md: 28 }}
      >
        <Skeleton
          width={'100%'}
          height={{ md: '100px', base: '90px' }}
          fadeDuration={1}
        >
          <Heading
            fontWeight={600}
            fontSize={{ base: '2xl', sm: '4xl', md: '6xl' }}
            lineHeight={'150%'}
          ></Heading>
        </Skeleton>
        <Skeleton height={{ base: '40px', md: '70px' }} width={'70%'} />
        <Skeleton height="120px" width={'80%'} pt={12} />
        <Skeleton height={{ base: '40px', md: '70px' }} width={'70%'} />
        <Skeleton height="120px" width={'80%'} pt={3} />
      </Stack>
    </Container>
  );
}
