import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Input,
  InputGroup,
  InputRightAddon,
  List,
  ListIcon,
  ListItem,
  SimpleGrid,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { FaCheckCircle, FaTimes, FaTimesCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { FaClock } from 'react-icons/fa';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
const dayOption = [
  { id: 2 },
  { id: 4 },
  { id: 8 },
  { id: 10 },
  { id: 5 },
  { id: 6 },
  { id: 7 },
  { id: 200 },
  { id: 500 },
  { id: 1000 },
  { id: 5000 },
  { id: 10000 },
];

const weekOption = [
  { id: 1 },
  { id: 2 },
  { id: 3 },
  { id: 4 },
  { id: 5 },
  { id: 6 },
  { id: 7 },
  { id: 200 },
  { id: 500 },
  { id: 1000 },
  { id: 5000 },
  { id: 10000 },
];

const monthOption1 = [
  { id: 3 },
  { id: 5 },
  { id: 7 },
  { id: 8 },
  { id: 10 },
  { id: 14 },
  { id: 18 },
  { id: 20 },
  { id: 50 },
  { id: 100 },
  { id: 200 },
  { id: 500 },
];

const monthNames = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
];

const PackageTier = ({
  title,
  currentDate,
  option1,
  option2,
  checked = false,
  type,
  targetDate,
}) => {
  const colorTextLight = checked ? 'white' : 'purple.600';
  const bgColorLight = checked ? 'purple.400' : 'gray.300';

  const colorTextDark = checked ? 'white' : 'purple.500';
  const bgColorDark = checked ? 'purple.400' : 'gray.300';
  const urlDate = dayjs(targetDate).format('YYYY-MM-DD');
  const [inputDate, setInputDate] = useState();
  const handleChange = e => {
    const { value } = e.target;
    if (/^[0-9]*$/.test(value)) setInputDate(value);
  };
  const handleKeyPress = e => {
    const keyCode = e.keyCode || e.which;
    const key = String.fromCharCode(keyCode);
    if (!/[0-9]/.test(key)) {
      e.preventDefault();
    }
  };
  const handleInput = e => {
    const { value } = e.target;
    if (value.length > 10) {
      e.preventDefault();
    }
  };
  const navigate = useNavigate();
  return (
    // <Stack
    //   p={3}
    //   py={3}
    //   justifyContent={{
    //     base: 'flex-start',
    //     md: 'space-between',
    //   }}
    //   direction={{
    //     base: 'column',
    //     lg: 'row',
    //   }}
    // >
    //   <Stack
    //     direction={'column'}
    //     spacing={{ base: 6, md: 8 }}
    //     align={'center'}
    //     alignSelf={'center'}
    //     // position={'relative'}
    //   >
    //     <Heading size={'lg'}>{title}</Heading>
    //     <InputGroup maxW={'60%'} size={'sm'} pb={8}>
    //       <Input
    //         type="number"
    //         placeholder="Select Date and Time"
    //         // onChange={e => setChangedDate(e.target.value)}
    //       />
    //       <InputRightAddon
    //         children="Go"
    //         colorScheme={'green'}
    //         color={'white'}
    //         bg={'green.400'}
    //         px={6}
    //         cursor={'pointer'}
    //         _hover={{
    //           bg: 'green.500',
    //         }}
    //         // onClick={() => parameter()}
    //       />
    //     </InputGroup>
    //   </Stack>

    //   <Stack align={'center'} alignSelf={'center'} >
    //     <List spacing={2} textAlign="start">
    //       <SimpleGrid columns={{ md: 2 }} spacing={{ base: 1, sm: 2, md: 4 }}>
    //         {option1.map(value => (
    //           <Link to={`/${type}s-from/${value.id}-${type}s-from-${urlDate}`}>
    //             <ListItem
    //               key={value.id}
    //               _hover={{
    //                 textDecoration: 'underline',
    //                 color: 'green.500',
    //               }}
    //             >
    //               <ListIcon as={FaClock} color="green.500" />
    //               {value.id} {type}s:{' '}
    //               {`${dayjs(currentDate)
    //                 .add(value.id, `${type}`)
    //                 .format('MMMM DD, YYYY')}`}
    //             </ListItem>
    //           </Link>
    //         ))}
    //       </SimpleGrid>
    //     </List>
    //   </Stack>

    // </Stack>
    <Stack
      // p={3}
      py={{ base: 10, md: 26 }}
      justifyContent={{
        base: 'center',
        md: 'space-between',
      }}
      direction={{
        base: 'column',
        lg: 'column',
      }}
    >
      <Stack
        direction={'column'}
        spacing={{ base: 6, md: 8 }}
        align={'center'}
        alignSelf={'center'}
        textAlign={'center'}
        // position={'relative'}
      >
        <Heading
          fontWeight={600}
          fontSize={{ base: '2xl', sm: '3xl', md: '5xl' }}
        >
          {title}
        </Heading>
        <InputGroup maxW={'60%'} size={'md'} pb={8}>
          {type !== 'next' && (
            <>
              <Input
                type="text"
                maxLength={10}
                value={inputDate}
                onKeyPress={handleKeyPress}
                onChange={handleChange}
                placeholder="Enter number"
              />
              <InputRightAddon
                children="Go"
                colorScheme={'green'}
                color={'white'}
                bg={'green.400'}
                px={6}
                cursor={'pointer'}
                _hover={{
                  bg: 'green.500',
                }}
                onClick={() => {
                  if (parseInt(inputDate) === 1) {
                    navigate(
                      `/${type}s-from/${inputDate}-${type}-from-${urlDate}`
                    );
                  } else {
                    navigate(
                      `/${type}s-from/${inputDate}-${type}s-from-${urlDate}`
                    );
                  }
                }}
              />
            </>
          )}
        </InputGroup>
      </Stack>

      <Stack
        as="Box"
        direction={'column'}
        spacing={{ base: 6, md: 8 }}

        // align={'center'}
        // alignSelf={'center'}
        // textAlign={'center'}
      >
        <List spacing={6} textAlign="start">
          <SimpleGrid
            columns={{ md: 3, sm: 2 }}
            spacing={{ base: 1, sm: 2, md: 4 }}
            justifyContent={{
              md: 'space-between',
              base: 'center',
            }}
          >
            {type === 'next' ? (
              <>
                {option1.map(value => (
                  <Link to={`/next-month/next-${value}-from-${urlDate}`}>
                    <ListItem
                      key={value}
                      textTransform={'capitalize'}
                      _hover={{
                        textDecoration: 'underline',
                        color: 'green.500',
                      }}
                    >
                      <ListIcon as={FaClock} color="green.500" />
                      {value}
                    </ListItem>
                  </Link>
                ))}
              </>
            ) : (
              <>
                {option1.map(value => (
                  <Link
                    to={`/${type}s-from/${value.id}-${type}s-from-${urlDate}`}
                  >
                    <ListItem
                      key={value.id}
                      _hover={{
                        textDecoration: 'underline',
                        color: 'green.500',
                      }}
                    >
                      <ListIcon as={FaClock} color="green.500" />
                      {value.id} {type}s:{' '}
                      {`${dayjs(targetDate)
                        .add(value.id, `${type}`)
                        .format('MMMM DD, YYYY')}`}
                    </ListItem>
                  </Link>
                ))}
              </>
            )}
          </SimpleGrid>
        </List>
      </Stack>
    </Stack>
  );
};
const ThreeTierPricingHorizontal = ({
  currentDate,
  isFutureDate,
  targetDate,
}) => {
  return (
    <Box py={6} px={4} min={'100vh'}>
      <Stack spacing={4} width={'100%'} direction={'column'}>
        <Divider />
        <PackageTier
          title={`Days from ${currentDate}`}
          isFutureDate={isFutureDate}
          date={currentDate}
          targetDate={targetDate}
          option1={dayOption}
          type={'day'}
        />
        <Divider />
        <PackageTier
          title={`Months from ${currentDate}`}
          isFutureDate={isFutureDate}
          date={currentDate}
          option1={monthOption1}
          // option2={dayOption2}
          targetDate={targetDate}
          type={'month'}
        />
        <Divider />
        <PackageTier
          title={`Weeks from ${currentDate}`}
          isFutureDate={isFutureDate}
          targetDate={targetDate}
          date={currentDate}
          option1={monthOption1}
          // option2={dayOption2}
          type={'week'}
        />
        <Divider />
        <PackageTier
          title={`Next Month from ${currentDate}`}
          isFutureDate={isFutureDate}
          date={currentDate}
          option1={monthNames}
          // option2={dayOption2}
          type={'next'}
        />
        <Divider />
        {/* <PackageTier title={'Lorem Pro'} typePlan="$50.00" /> */}
      </Stack>
    </Box>
  );
};

export default ThreeTierPricingHorizontal;
