import React from 'react';
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
} from '@chakra-ui/react';
import { ColorModeSwitcher } from './ColorModeSwitcher';
import { Logo } from './Logo';
import { Routes, Route, Navigate } from 'react-router-dom';
import Home from './pages/home';
import TimeCalculate from './pages/timeCalculate';
import theme from './theme';
import InvalidDate from './components/error';
import { DaysFrom } from './pages/daysFrom';
import NextMonth from './pages/nextMonth';
import Footer from './components/footer';
import { WeeksFrom } from './pages/weeksFrom';
import { MonthsFrom } from './pages/monthsFrom';
import Contact from './pages/contact';
import { PrivacyPolicy } from './pages/privacyPolicy';
import TermsAndCondition from './pages/termsAndCondition';
import { About } from './pages/about';
import { ContactEmail } from './pages/contact-email';
function App() {
  return (
    <ChakraProvider theme={theme}>
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/not-found" element={<InvalidDate />}></Route>
        <Route path="/date/:slug" element={<TimeCalculate />}></Route>
        <Route path="/days-from/:slug" element={<DaysFrom />}></Route>
        <Route path="/weeks-from/:slug" element={<WeeksFrom />}></Route>
        <Route path="/months-from/:slug" element={<MonthsFrom />}></Route>
        <Route path="/next-month/:slug" element={<NextMonth />}></Route>
        {/* <Route path="/contact" element={<Contact />}></Route> */}
        <Route path="/contact" element={<ContactEmail />}></Route>
        <Route path="/about" element={<About />}></Route>
        <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
        <Route
          path="/terms-and-conditions"
          element={<TermsAndCondition />}
        ></Route>
        <Route path="*" element={<Navigate to="/not-found" />}></Route>
      </Routes>
      {/* <Box textAlign="center" fontSize="xl">
        <Grid minH="100vh" p={3}>
          <ColorModeSwitcher justifySelf="flex-end" />
          <VStack spacing={8}>
            <Logo h="40vmin" pointerEvents="none" />
            <Text>
              Edit <Code fontSize="xl">src/App.js</Code> and save to reload.
            </Text>
            <Link
              color="teal.500"
              href="https://chakra-ui.com"
              fontSize="2xl"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn Chakra
            </Link>
          </VStack>
        </Grid>
      </Box> */}
    </ChakraProvider>
  );
}

export default App;
