import { Container, Stack, Text, Box, Heading } from '@chakra-ui/react';
import React from 'react';
import Footer from '../components/footer';
import Meta from '../components/Meta';
import Nav from '../components/navbar';

export const About = () => {
  return (
    <>
      <Nav />
      <Meta
        pageTitle="About"
        pageDescription="Welcome to HowLongism, the ultimate destination for date calculations! Calculate the time that has passed or the time remaining until a specific date."
      />
      <Container maxW={'6xl'} minH={'96vh'}>
        <Stack
          as={Box}
          spacing={{ base: 10, md: 14 }}
          py={{ base: 10, md: 20 }}
        >
          <Heading
            as="h1"
            color={'green.500'}
            textAlign={'center'}
            fontWeight={600}
            fontSize={{ base: '3xl', sm: '4xl', md: '6xl' }}
            id="page-description"
          >
            About Us
          </Heading>
        </Stack>
        <Stack
          spacing={{ base: 4, md: 6 }}
          textAlign="justify"
          fontSize={{ base: '16px', sm: '16px', md: '18px' }}
        >
          <Text>
            Welcome to HowLongism, the ultimate destination for date
            calculations! Our website allows you to easily calculate the time
            that has passed or the time remaining until a specific date. We have
            covered whether you're trying to figure out how long ago an
            important event occurred or how long you have until an upcoming
            deadline.
          </Text>
          <Text>
            Our user-friendly interface makes it simple for anyone to use,
            regardless of technical experience. Input the date you're interested
            in, and our website will instantly provide you with the necessary
            information. We offer both the option to calculate how long ago the
            date is from today's date and how long until the date from today's
            date.
          </Text>
          <Text>
            We constantly work to improve and add new features. So check back
            often for updates. If you have any suggestions or feedback, please
            don't hesitate to contact us. Thanks for visiting, and we hope you
            find our website useful!
          </Text>
        </Stack>
      </Container>
      <Footer />
    </>
  );
};
