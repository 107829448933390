import dayjs from 'dayjs';
export function getOrdinalIndicator(num) {
  return num === 1 ? 'st' : num === 2 ? 'nd' : num === 3 ? 'rd' : 'th';
}

export function findWeekend(num) {
  return num === 1 ? 'st' : num === 2 ? 'nd' : num === 3 ? 'rd' : 'th';
}

export const calculateWeekdaysAndWeekends = (startDate, targetDate) => {
  let weekday = 0;
  let weekend = 0;

  // Iterate over the days and count the weekdays and weekends
  let enddate = targetDate;
  // if (!dayjs.isDayjs(targetDate)) {
  //   enddate = dayjs(targetDate);
  // }

  // Calculate the difference in days between the start and end dates
  const diffDays = dayjs(enddate).diff(startDate, 'day');
  // Loop through the days and increment the appropriate counter
  for (let i = 1; i <= Math.abs(diffDays); i++) {
    let currentDate;
    if (targetDate > startDate) {
      currentDate = dayjs(startDate).add(i, 'day');
    } else {
      currentDate = dayjs(startDate).subtract(i, 'day');
    }

    if (currentDate.day() === 6) {
      weekend++;
    } else if (currentDate.day() === 0) {
      // break
    } else {
      weekday++;
    }
  }

  return {
    weekday,
    weekend,
  };
};

export function checkValidDate(dateString) {
  let date = dayjs(dateString);

  // Check if the date is valid by checking the time value of the Date object
  return date.isValid();
}

// const storeDescription = ({
//   formattedTargetDate,
//   isFutureDate,
//   timeRemaining,
// }) => {
//   const content = `${formattedTargetDate}  ${
//     isFutureDate ? 'is in' : timeRemaining.days === 0 ? 'is ' : 'was '
//   }  ${
//     timeRemaining.years > 0
//       ? `${timeRemaining.years}
//       ${timeRemaining.years === 1 ? 'year' : 'years'}`
//       : ''
//   } ${
//     timeRemaining.months > 0
//       ? `
//       ${timeRemaining.years !== 0 ? ', ' : ''}
//       ${timeRemaining.months}
//       ${timeRemaining.months === 1 ? 'month' : 'months'}
//     `
//       : ''
//   } ${
//     timeRemaining.days > 0
//       ? `
//         ${
//           timeRemaining.months !== 0 || timeRemaining.years !== 0
//             ? ' and'
//             : ''
//         }
//         ${timeRemaining.days}
//         ${timeRemaining.days === 1 ? 'day' : 'days'}`
//       : ''
//   }${isFutureDate || timeRemaining.days === 0 ? '' : ' ago'}${
//     timeRemaining.years === 0 &&
//     timeRemaining.days === 0 &&
//     timeRemaining.months === 0 &&
//     !isFutureDate
//       ? 'today'
//       : ''
//   }${
//     timeRemaining.years === 0 &&
//     timeRemaining.days === 0 &&
//     timeRemaining.months === 0 &&
//     isFutureDate
//       ? 'tomorrow'
//       : ''
//   }. Find how many years, months, weeks, days, hours, minutes, and seconds ${
//     isFutureDate ? 'until ' : 'since '
//   } ${formattedTargetDate}.`;
//   const trimmedContent = content.replace(/\s+/g, ' ');
//   return trimmedContent;
// };

// const calculateWeekdaysAndWeekends = (startDate, targetDate) => {
//   let weekday = 0;
//   let weekend = 0;

//   // Iterate over the days and count the weekdays and weekends
//   let enddate = targetDate;
//   // if (!dayjs.isDayjs(targetDate)) {
//   //   enddate = dayjs(targetDate);
//   // }

//   // Calculate the difference in days between the start and end dates
//   const diffDays = dayjs(enddate).diff(startDate, 'day');
//   // Loop through the days and increment the appropriate counter
//   for (let i = 1; i <= Math.abs(diffDays); i++) {
//     let currentDate;
//     if (targetDate > startDate) {
//       currentDate = dayjs(startDate).add(i, 'day');
//     } else {
//       currentDate = dayjs(startDate).subtract(i, 'day');
//     }

//     if (currentDate.day() === 6) {
//       weekend++;
//     } else if (currentDate.day() === 0) {
//       // break
//     } else {
//       weekday++;
//     }
//   }

//   return {
//     weekday,
//     weekend,
//   };
// };
