import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import Nav from '../components/navbar';
import {
  Box,
  Text,
  Button,
  Input,
  Container,
  Stack,
  Heading,
  Flex,
  InputGroup,
  InputRightAddon,
  SimpleGrid,
  Spacer,
  useToast,
} from '@chakra-ui/react';
import Simmer from '../components/simmer';
import Footer from '../components/footer';
import Meta from '../components/Meta';
import ShareButtons from '../components/shareButtons';

var weekOfYear = require('dayjs/plugin/weekOfYear');
var weekday = require('dayjs/plugin/weekday');
var isLeapYear = require('dayjs/plugin/isLeapYear');
var dayOfYear = require('dayjs/plugin/dayOfYear');
var quarterOfYear = require('dayjs/plugin/quarterOfYear');
var calendar = require('dayjs/plugin/calendar');
var duration = require('dayjs/plugin/duration');
dayjs.extend(duration);
dayjs.extend(calendar);
dayjs.extend(dayOfYear);
dayjs.extend(isLeapYear);
dayjs.extend(weekday);
dayjs.extend(weekOfYear);
dayjs.extend(quarterOfYear);
const monthNames = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
];

const NextMonth = () => {
  const [timeUntilNextMonth, setTimeUntilNextMonth] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const urlPath = location.pathname;
  const splitedURL = location.pathname.split('/');
  const splitedHiphenVlaue = urlPath.split('/')[2].split('-');
  const urlDate = dayjs(
    `${splitedHiphenVlaue[splitedHiphenVlaue.length - 3]}-${
      splitedHiphenVlaue[splitedHiphenVlaue.length - 2]
    }-${splitedHiphenVlaue[splitedHiphenVlaue.length - 1]}`
  ).$d;

  const monthFromURL = splitedURL[splitedURL.length - 1].split('-')[1];
  const capitalizeMonth =
    monthFromURL.charAt(0).toUpperCase() + monthFromURL.slice(1);
  const yearFromURL = splitedURL[splitedURL.length - 1].split('-')[3];
  const [selectedMonth, setSelectedMonth] = useState(
    // splitedURL[splitedURL.length - 1]
    splitedURL[splitedURL.length - 1].split('-')[1]
    // 'may'
  );

  const currentYear = dayjs(urlDate).format('YYYY');

  const [targetDate, setTargetDate] = useState(
    dayjs(`${currentYear}-${selectedMonth}-01`).$d
  );
  const [currentDate, setCurrentDate] = useState(dayjs(urlDate).$d);
  const [formattedTargetDate, setFormattedTargetDate] = useState(
    `${capitalizeMonth} from ${dayjs(urlDate).format('MMMM DD, YYYY')}`
  );

  // Declare a state variable for the time remaining
  const [timeRemaining, setTimeRemaining] = useState(null);
  const [isFutureDate, setIsFutureDate] = useState();
  const [weekends, setWeekends] = useState(0);
  const [weekdays, setWeekdays] = useState(0);
  const [showWeekend, setShowWeekend] = useState(null);
  const options = {
    // weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };
  const [selectedMonthDate, setSelectedMonthDate] = useState();

  const [changedDate, setChangedDate] = useState(
    dayjs(targetDate).format('YYYY-MM-DD')
  );

  const inputDefaultValue = dayjs(targetDate).format('YYYY-MM-DD');

  const calculateTimeRemaining = currentDate => {
    // Get the current time
    const currentTime = new Date();
    const startDate = new Date(0);
    //
    let targetDate;
    const today = dayjs(currentDate).$d;
    const currentMonth = today.getMonth();
    const currentYear = today.getFullYear();
    const selectedMonthIndex = monthNames.indexOf(selectedMonth);
    dayjs.extend(relativeTime);

    if (currentMonth < selectedMonthIndex) {
      targetDate = dayjs(`${currentYear}-${selectedMonth}-01`).$d;
      setSelectedMonthDate(dayjs(`${currentYear}-${selectedMonth}-01`).$d);
      setTargetDate(dayjs(`${currentYear}-${selectedMonth}-01`).$d);
    } else {
      const newYear = currentYear + 1;
      setSelectedMonthDate(dayjs(`${newYear}-${selectedMonth}-01`).$d);
      setTargetDate(dayjs(`${newYear}-${selectedMonth}-01`).$d);
      targetDate = dayjs(`${newYear}-${selectedMonth}-01`).$d;
    }

    // const testDate = targetDate.setHours(0, 0, 0, 0);
    // Calculate the time remaining
    if (targetDate > currentDate) {
      // The target date is in the future
      setIsFutureDate(true);
    } else {
      // The target date is in the past
      setIsFutureDate(false);
    }

    let timeDifference;
    timeDifference = dayjs(targetDate).diff(dayjs(currentDate));

    const years = Math.abs(dayjs.duration(timeDifference).years());
    const months = Math.abs(dayjs.duration(timeDifference).months());
    const days = Math.abs(dayjs.duration(timeDifference).days());
    const hours = Math.abs(dayjs.duration(timeDifference).hours());
    const minutes = Math.abs(dayjs.duration(timeDifference).minutes());
    const seconds = Math.abs(dayjs.duration(timeDifference).seconds());
    const weeks = Math.abs(dayjs.duration(timeDifference).weeks());

    if (Math.abs(dayjs.duration(timeDifference).years()) <= 200) {
      setShowWeekend(true);
      const weekdaysAndWeekends = calculateWeekdaysAndWeekends(
        currentDate,
        targetDate
      );
      const weekdays = weekdaysAndWeekends.weekday;
      const weekends = weekdaysAndWeekends.weekend;
      setWeekdays(weekdays);
      setWeekends(weekends);
    }

    // Update the timeRemaining state variable
    setTimeRemaining({
      years,
      months,
      days,
      hours,
      minutes,
      seconds,
    });
  };

  const parameter = () => {
    if (changedDate !== '') {
      setTargetDate(dayjs(`${changedDate}`).$d);

      navigate(`/date/${changedDate}`);
    } else {
      // toast({
      //   position: 'top-right',
      //   title: 'Please select the date',
      //   // description: 'Please select the date',
      //   status: 'error',
      //   duration: 3000,
      //   isClosable: true,
      // });
    }
  };
  const calculateWeekdaysAndWeekends = (startDate, targetDate) => {
    let weekday = 0;
    let weekend = 0;

    // Iterate over the days and count the weekdays and weekends
    let enddate = targetDate;
    // if (!dayjs.isDayjs(targetDate)) {
    //   enddate = dayjs(targetDate);
    // }

    // Calculate the difference in days between the start and end dates
    const diffDays = dayjs(enddate).diff(startDate, 'day');
    // Loop through the days and increment the appropriate counter
    for (let i = 1; i <= Math.abs(diffDays); i++) {
      let currentDate;
      if (targetDate > startDate) {
        currentDate = dayjs(startDate).add(i, 'day');
      } else {
        currentDate = dayjs(startDate).subtract(i, 'day');
      }

      if (currentDate.day() === 6) {
        weekend++;
      } else if (currentDate.day() === 0) {
        // break
      } else {
        weekday++;
      }
    }

    return {
      weekday,
      weekend,
    };
  };

  const convertUrlToDate = selectedMonth => {
    const today = new Date();
    const currentMonth = today.getMonth();
    const currentYear = today.getFullYear();
    const selectedMonthIndex = monthNames.indexOf(selectedMonth);
    dayjs.extend(relativeTime);

    if (currentMonth < selectedMonthIndex) {
      setSelectedMonthDate(dayjs(`${currentYear}-${selectedMonth}-01`));
      setTargetDate(dayjs(`${currentYear}-${selectedMonth}-01`));
    } else {
      const newYear = currentYear + 1;
      setSelectedMonthDate(dayjs(`${newYear}-${selectedMonth}-01`).$d);
      setTargetDate(dayjs(`${newYear}-${selectedMonth}-01`).$d);
    }
  };
  // useEffect(() => {
  //   if (selectedMonth) {
  //     convertUrlToDate(selectedMonth);
  //   }
  // }, [selectedMonth]);

  function checkValidDate(selectedMonth) {
    if (monthNames.includes(selectedMonth)) {
      return true;
    } else {
      return false;
    }
  }
  useEffect(() => {
    if (checkValidDate(selectedMonth)) {
      const interval = setInterval(calculateTimeRemaining(currentDate), 1000);
      return () => clearInterval(interval);
    } else {
      navigate('/not-found');
    }
  }, [selectedMonth]);
  function getOrdinalIndicator(num) {
    return num === 1 ? 'st' : num === 2 ? 'nd' : num === 3 ? 'rd' : 'th';
  }

  const storeDescription = ({
    formattedTargetDate,
    isFutureDate,
    timeRemaining,
  }) => {
    const content = `Next ${formattedTargetDate}  ${
      isFutureDate ? 'is in' : timeRemaining.days === 0 ? 'is ' : 'was '
    }  ${
      timeRemaining.years > 0
        ? `${timeRemaining.years}
        ${timeRemaining.years === 1 ? 'year' : 'years'}`
        : ''
    }${isFutureDate || timeRemaining.days === 0 ? '' : ' ago'}${
      timeRemaining.months > 0
        ? `
        ${timeRemaining.years !== 0 ? ', ' : ''}
        ${timeRemaining.months}
        ${timeRemaining.months === 1 ? 'month' : 'months'}
      `
        : ''
    } ${
      timeRemaining.days > 0
        ? `
          ${
            timeRemaining.months !== 0 || timeRemaining.years !== 0
              ? ' and'
              : ''
          }
          ${timeRemaining.days}
          ${timeRemaining.days === 1 ? 'day' : 'days'}`
        : ''
    }. Find how many years, months, weeks, days, hours, minutes, and seconds ${
      isFutureDate ? 'until next ' : 'since '
    } ${formattedTargetDate}.`;
    return content;
  };
  return (
    <>
      <Box>
        <Nav />
        {timeRemaining ? (
          <>
            <Meta
              pageTitle={`How Long Until Next ${selectedMonth} from ${dayjs(
                urlDate
              ).format('MMMM DD, YYYY')}?`}
              pageDescription={storeDescription({
                formattedTargetDate,
                isFutureDate,
                timeRemaining,
              })}
            />
            <Container maxW="6xl">
              <Box>
                <Stack
                  as={Box}
                  textAlign={'center'}
                  spacing={{ base: 10, md: 14 }}
                  pt={{ base: 10, md: 20 }}
                  pb={{ base: 10, md: 12 }}
                >
                  <Heading
                    as="h1"
                    fontWeight={600}
                    fontSize={{ base: '2xl', sm: '4xl', md: '6xl' }}
                    id="page-description"
                    textTransform={'capitalize'}
                  >
                    How Long Until Next {selectedMonth} from{' '}
                    {dayjs(urlDate).format('MMMM DD, YYYY')}?
                  </Heading>
                  <Text
                    fontWeight={600}
                    fontSize={{ base: '2xl', sm: '3xl', md: '5xl' }}
                    lineHeight={'150%'}
                    textTransform={'capitalize'}
                  >
                    {/* {targetDate
                  .toLocaleDateString('en-us', options)
                  .replace(',', ' ')} */}
                    {/* {targetDate.toLocaleDateString('en-us', options)} */}
                    Next {selectedMonth} from{' '}
                    {dayjs(urlDate).format('MMMM DD, YYYY')}
                    <Text as={'span'} textTransform={'lowercase'}>
                      {isFutureDate ? <> is in</> : <> was</>}
                    </Text>
                    <Spacer />
                  </Text>

                  <Stack
                    direction={'row'}
                    spacing={{ md: 10, xl: 16, sm: 10, base: 2 }}
                    textAlign={'center'}
                    justifyContent={'center'}
                    fontWeight={'600'}
                    color={'green.500'}
                    //   align={'center'}
                    //   alignSelf={'center'}
                    //   position={'relative'}
                  >
                    <Flex direction={'column'}>
                      <Text fontSize={{ base: '2xl', sm: '3xl', md: '7xl' }}>
                        {timeRemaining.years}
                      </Text>
                      <Text
                        fontSize={{ base: '16px', sm: '18px', md: '26px' }}
                        fontWeight={400}
                      >
                        {timeRemaining.years === 1 ? <>Year</> : <>Years</>}
                      </Text>
                    </Flex>
                    <Flex direction={'column'}>
                      <Text fontSize={{ base: '2xl', sm: '3xl', md: '7xl' }}>
                        {timeRemaining.months}
                      </Text>
                      <Text
                        fontSize={{ base: '16px', sm: '18px', md: '26px' }}
                        fontWeight={400}
                      >
                        {timeRemaining.months === 1 ? <>Month</> : <>Months</>}
                      </Text>
                    </Flex>
                    <Flex direction={'column'}>
                      <Text fontSize={{ base: '2xl', sm: '3xl', md: '7xl' }}>
                        {timeRemaining.days}
                      </Text>
                      <Text
                        fontSize={{ base: '16px', sm: '18px', md: '26px' }}
                        fontWeight={400}
                      >
                        {timeRemaining.days === 1 ? <>Day</> : <>Days</>}
                      </Text>
                    </Flex>
                    <Flex direction={'column'}>
                      <Text fontSize={{ base: '2xl', sm: '3xl', md: '7xl' }}>
                        {timeRemaining.hours}
                      </Text>
                      <Text
                        fontSize={{ base: '16px', sm: '18px', md: '26px' }}
                        fontWeight={400}
                      >
                        {timeRemaining.hours === 1 ? <>Hour</> : <>Hours</>}
                      </Text>
                    </Flex>
                    <Flex direction={'column'}>
                      <Text fontSize={{ base: '2xl', sm: '3xl', md: '7xl' }}>
                        {timeRemaining.minutes}
                      </Text>
                      <Text
                        fontSize={{ base: '16px', sm: '18px', md: '26px' }}
                        fontWeight={400}
                      >
                        {timeRemaining.minutes === 1 ? (
                          <>Minute</>
                        ) : (
                          <>Minutes</>
                        )}
                      </Text>
                    </Flex>
                    {/* <Flex direction={'column'}>
                      <Text fontSize={{ base: '2xl', sm: '3xl', md: '7xl' }}>
                        {timeRemaining.seconds}
                      </Text>
                      <Text
                        fontSize={{ base: '16px', sm: '18px', md: '26px' }}
                        fontWeight={400}
                      >
                        {timeRemaining.seconds === 1 ? (
                          <>Second</>
                        ) : (
                          <>Seconds</>
                        )}
                      </Text>
                    </Flex> */}
                    {/* {timeRemaining.hours} hours,{' '}
                        {timeRemaining.minutes} minutes, {timeRemaining.seconds}{' '}
                        seconds */}
                  </Stack>
                  <Stack
                    direction={'Column'}
                    alignSelf={'center'}
                    spacing={3}
                    justifyContent={'center'}
                    position={'relative'}
                  >
                    <Text alignSelf={'start'} pb={2} fontSize={{ md: '18px' }}>
                      Try Using Another Date:
                    </Text>

                    <InputGroup>
                      <Input
                        type="date"
                        placeholder="Select Date and Time"
                        defaultValue={inputDefaultValue}
                        onChange={e => setChangedDate(e.target.value)}
                      />
                      <InputRightAddon
                        children="Go"
                        colorScheme={'green'}
                        color={'white'}
                        bg={'green.400'}
                        px={6}
                        cursor={'pointer'}
                        _hover={{
                          bg: 'green.500',
                        }}
                        onClick={() => parameter()}
                      />
                    </InputGroup>
                  </Stack>
                  <ShareButtons urlToShare={urlPath} />
                </Stack>
                <Box>
                  <Stack
                    spacing={{ md: 10, xl: 16, sm: 10, base: 2 }}
                    textAlign={'center'}
                    justifyContent={'center'}
                    pb={{ base: 8, md: 12 }}
                  >
                    <Text fontSize={{ base: '18px', sm: '22px', md: '24px' }}>
                      {targetDate.toLocaleDateString('en-us', options)}{' '}
                      {isFutureDate ? (
                        <>is in </>
                      ) : timeRemaining.days === 0 ? (
                        <>is </>
                      ) : (
                        <>was </>
                      )}
                      {timeRemaining.years > 0 && (
                        <>
                          {timeRemaining.years}{' '}
                          {timeRemaining.years === 1 ? <>year</> : <>years</>}
                        </>
                      )}
                      {timeRemaining.months > 0 && (
                        <>
                          {timeRemaining.years !== 0 && <>,</>}{' '}
                          {timeRemaining.months}{' '}
                          {timeRemaining.months === 1 ? (
                            <>month</>
                          ) : (
                            <>months</>
                          )}
                        </>
                      )}
                      {timeRemaining.days > 0 && (
                        <>
                          {timeRemaining.months !== 0 ||
                          timeRemaining.years !== 0 ? (
                            <>, and</>
                          ) : null}{' '}
                          {timeRemaining.days}{' '}
                          {timeRemaining.days === 1 ? <>day</> : <>days</>}
                        </>
                      )}
                      {timeRemaining.years === 0 &&
                        timeRemaining.days === 0 &&
                        timeRemaining.months === 0 && <>today</>}
                      {isFutureDate ? null : <> ago</>}. It{' '}
                      {isFutureDate ? <>will be</> : <>was</>} the{' '}
                      {dayjs(targetDate).dayOfYear()}
                      {getOrdinalIndicator(dayjs(targetDate).dayOfYear())} day
                      of {dayjs(targetDate).format('YYYY')} on{' '}
                      {dayjs(targetDate).format('dddd')}, which{' '}
                      {isFutureDate ? <>will be</> : <>was</>} a{' '}
                      {dayjs(targetDate).weekday() === 0 ||
                      dayjs(targetDate).weekday() === 6 ? (
                        <>weekend</>
                      ) : (
                        <>weekday</>
                      )}
                      . It {isFutureDate ? <>will be</> : <>was</>} on the{' '}
                      {dayjs(targetDate).week()}
                      {getOrdinalIndicator(dayjs(targetDate).week())} week and{' '}
                      {dayjs(targetDate).quarter()}
                      {getOrdinalIndicator(dayjs(targetDate).quarter())} quarter
                      of the
                      {dayjs(targetDate).isLeapYear() ? (
                        <> leap year (366 days).</>
                      ) : (
                        <> non-leap year (365 days).</>
                      )}{' '}
                    </Text>
                  </Stack>
                </Box>
                <Stack
                  as={Box}
                  textAlign={'center'}
                  spacing={{ base: 8, md: 14 }}
                  py={{ base: 1, md: 2 }}
                  mb={'14'}
                >
                  <SimpleGrid columns={{ sm: 1, md: 2 }} spacing={10}>
                    <Stack
                      as="Box"
                      bg="green.500"
                      color={'white'}
                      borderRadius={'md'}
                      py={{ base: 4, md: 6, lg: 18 }}
                      px={{ base: 3, md: 5, lg: 10 }}
                    >
                      <Text
                        fontSize={{
                          base: '18px',
                          sm: '20px',
                          md: '22px',
                          lg: '24px',
                        }}
                      >
                        How many years{' '}
                        {isFutureDate ? <>until </> : <>ago was </>}{' '}
                        {formattedTargetDate}?
                      </Text>
                      <Text
                        fontSize={{
                          base: '2xl',
                          sm: '4xl',
                          md: '38px',
                          lg: '46px',
                        }}
                      >
                        {Math.abs(dayjs(currentDate).diff(targetDate, 'years'))}
                        <Text
                          as={'span'}
                          fontSize={{ base: '16px', sm: '18px', md: '22px' }}
                        >
                          {Math.abs(
                            dayjs(currentDate).diff(targetDate, 'years')
                          ) === 1 ? (
                            <> Year</>
                          ) : (
                            <> Years</>
                          )}
                        </Text>
                      </Text>
                    </Stack>
                    <Stack
                      as="Box"
                      bg="green.500"
                      color={'white'}
                      borderRadius={'md'}
                      py={{ base: 4, md: 6, lg: 18 }}
                      px={{ base: 3, md: 5, lg: 10 }}
                    >
                      <Text
                        fontSize={{
                          base: '18px',
                          sm: '20px',
                          md: '22px',
                          lg: '24px',
                        }}
                      >
                        How many months{' '}
                        {isFutureDate ? <>until </> : <>ago was </>}{' '}
                        {formattedTargetDate}?
                      </Text>
                      <Text
                        fontSize={{
                          base: '2xl',
                          sm: '4xl',
                          md: '38px',
                          lg: '46px',
                        }}
                      >
                        {Math.abs(
                          dayjs(currentDate).diff(targetDate, 'months')
                        )}
                        <Text
                          as={'span'}
                          fontSize={{ base: '16px', sm: '18px', md: '22px' }}
                        >
                          {Math.abs(
                            dayjs(currentDate).diff(targetDate, 'months')
                          ) === 1 ? (
                            <> Month</>
                          ) : (
                            <> Months</>
                          )}
                        </Text>
                      </Text>
                    </Stack>
                    <Stack
                      as="Box"
                      bg="green.500"
                      // height="80px"
                      color={'white'}
                      borderRadius={'md'}
                      py={{ base: 4, md: 6, lg: 18 }}
                      px={{ base: 3, md: 5, lg: 10 }}
                    >
                      <Text
                        fontSize={{
                          base: '18px',
                          sm: '20px',
                          md: '22px',
                          lg: '24px',
                        }}
                      >
                        How many weeks{' '}
                        {isFutureDate ? <>until </> : <>ago was </>}{' '}
                        {formattedTargetDate}?
                      </Text>
                      <Text
                        fontSize={{
                          base: '2xl',
                          sm: '4xl',
                          md: '38px',
                          lg: '46px',
                        }}
                      >
                        {Math.abs(dayjs(currentDate).diff(targetDate, 'weeks'))}
                        <Text
                          as={'span'}
                          fontSize={{ base: '16px', sm: '18px', md: '22px' }}
                        >
                          {Math.abs(
                            dayjs(currentDate).diff(targetDate, 'weeks')
                          ) === 1 ? (
                            <> Week</>
                          ) : (
                            <> Weeks</>
                          )}
                        </Text>
                      </Text>
                    </Stack>
                    <Stack
                      as="Box"
                      bg="green.500"
                      color={'white'}
                      borderRadius={'md'}
                      py={{ base: 4, md: 6, lg: 18 }}
                      px={{ base: 3, md: 5, lg: 10 }}
                    >
                      <Text
                        fontSize={{
                          base: '18px',
                          sm: '20px',
                          md: '22px',
                          lg: '24px',
                        }}
                      >
                        How many days{' '}
                        {isFutureDate ? <>until </> : <>ago was </>}{' '}
                        {formattedTargetDate}?
                      </Text>
                      <Text
                        fontSize={{
                          base: '2xl',
                          sm: '4xl',
                          md: '38px',
                          lg: '46px',
                        }}
                      >
                        {Math.abs(dayjs(currentDate).diff(targetDate, 'days'))}
                        <Text
                          as={'span'}
                          fontSize={{ base: '16px', sm: '18px', md: '22px' }}
                        >
                          {Math.abs(
                            dayjs(currentDate).diff(targetDate, 'days')
                          ) === 1 ? (
                            <> Day</>
                          ) : (
                            <> Days</>
                          )}
                        </Text>
                      </Text>
                    </Stack>
                    <Stack
                      as="Box"
                      bg="green.500"
                      // height="80px"
                      color={'white'}
                      borderRadius={'md'}
                      py={{ base: 4, md: 6, lg: 18 }}
                      px={{ base: 3, md: 5, lg: 10 }}
                    >
                      <Text
                        fontSize={{
                          base: '18px',
                          sm: '20px',
                          md: '22px',
                          lg: '24px',
                        }}
                      >
                        How many hours{' '}
                        {isFutureDate ? <>until </> : <>ago was </>}{' '}
                        {formattedTargetDate}?
                      </Text>
                      <Text
                        fontSize={{
                          base: '2xl',
                          sm: '4xl',
                          md: '38px',
                          lg: '46px',
                        }}
                      >
                        {Math.abs(dayjs(currentDate).diff(targetDate, 'hours'))}
                        <Text
                          as={'span'}
                          fontSize={{ base: '16px', sm: '18px', md: '22px' }}
                        >
                          {Math.abs(
                            dayjs(currentDate).diff(targetDate, 'hours')
                          ) !== 1 ? (
                            <> Hours</>
                          ) : (
                            <> Hour</>
                          )}
                        </Text>
                      </Text>
                    </Stack>
                    <Stack
                      as="Box"
                      bg="green.500"
                      color={'white'}
                      borderRadius={'md'}
                      py={{ base: 4, md: 6, lg: 18 }}
                      px={{ base: 3, md: 5, lg: 10 }}
                    >
                      <Text
                        fontSize={{
                          base: '18px',
                          sm: '20px',
                          md: '22px',
                          lg: '24px',
                        }}
                      >
                        How many minutes{' '}
                        {isFutureDate ? <>until </> : <>ago was </>}{' '}
                        {formattedTargetDate}?
                      </Text>
                      <Text
                        fontSize={{
                          base: '2xl',
                          sm: '4xl',
                          md: '38px',
                          lg: '46px',
                        }}
                      >
                        {Math.abs(
                          dayjs(currentDate).diff(targetDate, 'minutes')
                        )}
                        <Text
                          as={'span'}
                          fontSize={{ base: '16px', sm: '18px', md: '22px' }}
                        >
                          {Math.abs(
                            dayjs(currentDate).diff(targetDate, 'minutes')
                          ) !== 1 ? (
                            <> Minutes</>
                          ) : (
                            <> Minute</>
                          )}
                        </Text>
                      </Text>
                    </Stack>
                    <Stack
                      as="Box"
                      bg="green.500"
                      color={'white'}
                      borderRadius={'md'}
                      py={{ base: 4, md: 6, lg: 18 }}
                      px={{ base: 3, md: 5, lg: 10 }}
                    >
                      <Text
                        fontSize={{
                          base: '18px',
                          sm: '20px',
                          md: '22px',
                          lg: '24px',
                        }}
                      >
                        How many seconds{' '}
                        {isFutureDate ? <>until </> : <>ago was </>}{' '}
                        {formattedTargetDate}?
                      </Text>
                      <Text
                        fontSize={{
                          base: '2xl',
                          sm: '4xl',
                          md: '38px',
                          lg: '46px',
                        }}
                      >
                        {Math.abs(
                          dayjs(currentDate).diff(targetDate, 'seconds')
                        )}
                        <Text
                          as={'span'}
                          fontSize={{ base: '16px', sm: '18px', md: '22px' }}
                        >
                          {Math.abs(
                            dayjs(currentDate).diff(targetDate, 'seconds')
                          ) !== 1 ? (
                            <> Seconds</>
                          ) : (
                            <> Second</>
                          )}
                        </Text>
                      </Text>
                    </Stack>
                    {showWeekend && (
                      <>
                        <Stack
                          as="Box"
                          bg="green.500"
                          // height="80px"
                          color={'white'}
                          borderRadius={'md'}
                          py={{ base: 4, md: 6, lg: 18 }}
                          px={{ base: 3, md: 5, lg: 10 }}
                        >
                          <Text
                            fontSize={{
                              base: '18px',
                              sm: '20px',
                              md: '22px',
                              lg: '24px',
                            }}
                          >
                            How many weekends{' '}
                            {isFutureDate ? <>until </> : <>ago was </>}{' '}
                            {formattedTargetDate}?
                          </Text>
                          <Text
                            fontSize={{
                              base: '2xl',
                              sm: '4xl',
                              md: '38px',
                              lg: '46px',
                            }}
                          >
                            {weekends}
                            <Text
                              as={'span'}
                              fontSize={{
                                base: '14px',
                                sm: '18px',
                                md: '22px',
                              }}
                            >
                              {weekends !== 1 ? <> Weekends</> : <> Weekend</>}
                            </Text>
                          </Text>
                        </Stack>
                        <Stack
                          as="Box"
                          bg="green.500"
                          color={'white'}
                          borderRadius={'md'}
                          py={{ base: 4, md: 6, lg: 18 }}
                          px={{ base: 3, md: 5, lg: 10 }}
                        >
                          <Text
                            fontSize={{
                              base: '18px',
                              sm: '20px',
                              md: '22px',
                              lg: '24px',
                            }}
                          >
                            How many weekdays{' '}
                            {isFutureDate ? <>until </> : <>ago was </>}{' '}
                            {formattedTargetDate}?
                          </Text>
                          <Text
                            fontSize={{
                              base: '2xl',
                              sm: '4xl',
                              md: '38px',
                              lg: '46px',
                            }}
                          >
                            {weekdays}
                            <Text
                              as={'span'}
                              fontSize={{
                                base: '14px',
                                sm: '18px',
                                md: '22px',
                              }}
                            >
                              {weekdays !== 1 ? <> Weekdays</> : <> Weekday</>}
                            </Text>
                          </Text>
                        </Stack>
                      </>
                    )}
                  </SimpleGrid>
                </Stack>
              </Box>
            </Container>
            <Footer />
          </>
        ) : (
          <Simmer />
        )}
      </Box>
    </>
  );
};

export default NextMonth;
