import React, { useEffect, useState } from 'react';
import {
  Box,
  Text,
  Button,
  Input,
  Container,
  Stack,
  Heading,
  Flex,
  InputGroup,
  InputRightAddon,
  SimpleGrid,
  Spacer,
  useToast,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import Calendar from 'react-calendar/dist/cjs/Calendar';
import Nav from '../components/navbar';
import Simmer from '../components/simmer';
import Meta from '../components/Meta';
import ThreeTierPricingHorizontal from './test';
import Footer from '../components/footer';
import ShareButtons from '../components/shareButtons';
import {
  calculateWeekdaysAndWeekends,
  checkValidDate,
  getOrdinalIndicator,
} from '../components/functions';
import { storeDescription } from '../components/storedDescription';
import TimeRemainingDescription from '../components/timeRemainingDescription';
import TimeCalculationDisplay from '../components/TimeCalculationDisplay';
import InvalidPage from '../components/InvalidPage';
var weekOfYear = require('dayjs/plugin/weekOfYear');
var weekday = require('dayjs/plugin/weekday');
var isLeapYear = require('dayjs/plugin/isLeapYear');
var dayOfYear = require('dayjs/plugin/dayOfYear');
var quarterOfYear = require('dayjs/plugin/quarterOfYear');
var calendar = require('dayjs/plugin/calendar');
var duration = require('dayjs/plugin/duration');
dayjs.extend(duration);
dayjs.extend(calendar);
dayjs.extend(dayOfYear);
dayjs.extend(isLeapYear);
dayjs.extend(weekday);
dayjs.extend(weekOfYear);
dayjs.extend(quarterOfYear);

export default function TimeCalculate() {
  const location = useLocation();
  const navigate = useNavigate();

  const toast = useToast();

  const urlPath = location.pathname;

  const getDateFromUrl = urlPath.split('/')[2];
  // Declare a state variable for the target date

  // const urlDateNew = ;
  // const urlDate = new Date(urlPath.substring(urlPath.length - 10));
  const urlDate = dayjs(getDateFromUrl).$d;
  const urlYear = getDateFromUrl.split('-')[0];
  const [targetDate, setTargetDate] = useState(dayjs(`${urlDate}`).$d);
  const [formattedTargetDate, setFormattedTargetDate] = useState(
    `${dayjs(`${urlDate}`).format('MMMM DD')}, ${urlYear}`
  );
  const [changedDate, setChangedDate] = useState(
    dayjs(targetDate).format('YYYY-MM-DD')
  );

  const inputDefaultValue = dayjs(targetDate).format('YYYY-MM-DD');

  // Declare a state variable for the time remaining
  const [timeRemaining, setTimeRemaining] = useState(null);
  const [isFutureDate, setIsFutureDate] = useState();
  const [weekends, setWeekends] = useState(0);
  const [showWeekend, setShowWeekend] = useState(null);
  const [weekdays, setWeekdays] = useState(0);
  const [isValidDate, setIsValidDate] = useState(true);

  // This function will calculate the time remaining
  // and update the timeRemaining state variable
  const calculateTimeRemaining = () => {
    // setPageTitle(`How Long ${isFutureDate ? <>Until </> : <>Ago Was </>}
    // ${formattedTargetDate}?`);
    // Get the current time
    const currentTime = dayjs().$d;
    // Calculate the time remaining
    let timeDifference;
    if (targetDate > currentTime) {
      // The target date is in the future
      timeDifference = targetDate - currentTime;
      setIsFutureDate(true);
    } else {
      // The target date is in the past
      timeDifference = currentTime - targetDate;
      setIsFutureDate(false);
    }

    timeDifference = dayjs(targetDate).diff(dayjs());

    const years = Math.abs(dayjs.duration(timeDifference).years());
    const months = Math.abs(dayjs.duration(timeDifference).months());
    const days = Math.abs(dayjs.duration(timeDifference).days());
    const hours = Math.abs(dayjs.duration(timeDifference).hours());
    const minutes = Math.abs(dayjs.duration(timeDifference).minutes());
    const seconds = Math.abs(dayjs.duration(timeDifference).seconds());
    const weeks = Math.abs(dayjs.duration(timeDifference).weeks());
    setTimeRemaining({
      years,
      months,
      days,
      hours,
      minutes,
      seconds,
    });
    if (Math.abs(dayjs.duration(timeDifference).years()) <= 200) {
      setShowWeekend(true);
      const weekdaysAndWeekends = calculateWeekdaysAndWeekends(
        currentTime,
        targetDate
      );
      const weekdays = weekdaysAndWeekends.weekday;
      const weekends = weekdaysAndWeekends.weekend;
      setWeekdays(weekdays);
      setWeekends(weekends);
    }

    // Update the timeRemaining state variable
  };

  const parameter = () => {
    if (changedDate !== '') {
      setTimeRemaining(null);
      // const currentTime = new Date();
      setTargetDate(dayjs(`${changedDate}`).$d);

      navigate(`/date/${changedDate}`);
    } else {
      toast({
        position: 'top-right',
        title: 'Please select the date',
        // description: 'Please select the date',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  // Use the useEffect hook to calculate the time remaining
  // every second and update the timeRemaining state variable
  useEffect(() => {
    // setTargetDate(new Date(urlDate));
    if (checkValidDate(urlDate)) {
      setIsValidDate(true);
      // setFormattedTargetDate(dayjs(`${urlDate}`).format('MMMM DD, YYYY'));\
      setFormattedTargetDate(
        `${dayjs(`${urlDate}`).format('MMMM DD')}, ${urlYear}`
      );
      const interval = setInterval(calculateTimeRemaining, 1000);
      return () => clearInterval(interval);
    } else {
      // navigate('/not-found');
      setTimeRemaining(null);
      setIsValidDate(false);
    }
  }, [urlDate]);
  // useEffect(() => {
  //   if (urlDate) {
  //
  //   }
  // }, [urlDate]);

  // Return the JSX that displays the time remaining

  return (
    <>
      <Box>
        <Nav />
        {isValidDate ? (
          <>
            {timeRemaining ? (
              <>
                <Meta
                  pageTitle={`How Long ${isFutureDate ? 'Until ' : 'Ago Was '}
                    ${formattedTargetDate}?`}
                  pageDescription={storeDescription({
                    formattedTargetDate,
                    isFutureDate,
                    timeRemaining,
                  })}
                />

                <Container maxW="6xl">
                  <Box>
                    <Stack
                      as={Box}
                      textAlign={'center'}
                      spacing={{ base: 10, md: 14 }}
                      pt={{ base: 10, md: 20 }}
                      pb={{ base: 10, md: 12 }}
                    >
                      <Heading
                        as="h1"
                        fontWeight={600}
                        fontSize={{ base: '3xl', sm: '4xl', md: '6xl' }}
                        id="page-description"
                      >
                        How Long {isFutureDate ? <>Until </> : <>Ago Was </>}
                        {formattedTargetDate}?
                      </Heading>
                      <Text
                        fontWeight={600}
                        fontSize={{ base: '2xl', sm: '3xl', md: '5xl' }}
                        lineHeight={'150%'}
                      >
                        {/* {targetDate.toLocaleDateString('en-us', options)} */}
                        {formattedTargetDate}
                        <Text as={'span'}>
                          {isFutureDate ? <> is in</> : <> was</>}
                        </Text>
                        <Spacer />
                      </Text>

                      <Stack
                        direction={'row'}
                        spacing={{ md: 10, xl: 16, sm: 10, base: 2 }}
                        textAlign={'center'}
                        justifyContent={'center'}
                        fontWeight={'600'}
                        color={'green.500'}
                        //   align={'center'}
                        //   alignSelf={'center'}
                        //   position={'relative'}
                      >
                        <Flex direction={'column'}>
                          <Text
                            fontSize={{ base: '2xl', sm: '3xl', md: '7xl' }}
                          >
                            {timeRemaining.years}
                          </Text>
                          <Text
                            fontSize={{ base: '16px', sm: '18px', md: '26px' }}
                            fontWeight={400}
                          >
                            {timeRemaining.years === 1 ? <>Year</> : <>Years</>}
                          </Text>
                        </Flex>
                        <Flex direction={'column'}>
                          <Text
                            fontSize={{ base: '2xl', sm: '3xl', md: '7xl' }}
                          >
                            {timeRemaining.months}
                          </Text>
                          <Text
                            fontSize={{ base: '16px', sm: '18px', md: '26px' }}
                            fontWeight={400}
                          >
                            {timeRemaining.months === 1 ? (
                              <>Month</>
                            ) : (
                              <>Months</>
                            )}
                          </Text>
                        </Flex>
                        <Flex direction={'column'}>
                          <Text
                            fontSize={{ base: '2xl', sm: '3xl', md: '7xl' }}
                          >
                            {timeRemaining.days}
                          </Text>
                          <Text
                            fontSize={{ base: '16px', sm: '18px', md: '26px' }}
                            fontWeight={400}
                          >
                            {timeRemaining.days === 1 ? <>Day</> : <>Days</>}
                          </Text>
                        </Flex>
                        <Flex direction={'column'}>
                          <Text
                            fontSize={{ base: '2xl', sm: '3xl', md: '7xl' }}
                          >
                            {timeRemaining.hours}
                          </Text>
                          <Text
                            fontSize={{ base: '16px', sm: '18px', md: '26px' }}
                            fontWeight={400}
                          >
                            {timeRemaining.hours === 1 ? <>Hour</> : <>Hours</>}
                          </Text>
                        </Flex>
                        <Flex direction={'column'}>
                          <Text
                            fontSize={{ base: '2xl', sm: '3xl', md: '7xl' }}
                          >
                            {timeRemaining.minutes}
                          </Text>
                          <Text
                            fontSize={{ base: '16px', sm: '18px', md: '26px' }}
                            fontWeight={400}
                          >
                            {timeRemaining.minutes === 1 ? (
                              <>Minute</>
                            ) : (
                              <>Minutes</>
                            )}
                          </Text>
                        </Flex>
                        <Flex direction={'column'}>
                          <Text
                            fontSize={{ base: '2xl', sm: '3xl', md: '7xl' }}
                          >
                            {timeRemaining.seconds}
                          </Text>
                          <Text
                            fontSize={{ base: '16px', sm: '18px', md: '26px' }}
                            fontWeight={400}
                          >
                            {timeRemaining.seconds === 1 ? (
                              <>Second</>
                            ) : (
                              <>Seconds</>
                            )}
                          </Text>
                        </Flex>
                        {/* {timeRemaining.hours} hours,{' '}
                        {timeRemaining.minutes} minutes, {timeRemaining.seconds}{' '}
                        seconds */}
                      </Stack>
                      <Stack
                        direction={'Column'}
                        alignSelf={'center'}
                        spacing={3}
                        justifyContent={'center'}
                        position={'relative'}
                      >
                        <Text
                          alignSelf={'start'}
                          pb={2}
                          fontSize={{ md: '18px' }}
                        >
                          Try Using Another Date:
                        </Text>

                        <InputGroup>
                          <Input
                            type="date"
                            placeholder="Select Date and Time"
                            defaultValue={inputDefaultValue}
                            onChange={e => setChangedDate(e.target.value)}
                          />
                          <InputRightAddon
                            children="Go"
                            colorScheme={'green'}
                            color={'white'}
                            bg={'green.400'}
                            px={6}
                            cursor={'pointer'}
                            _hover={{
                              bg: 'green.500',
                            }}
                            onClick={() => parameter()}
                          />
                        </InputGroup>
                      </Stack>
                      <ShareButtons urlToShare={urlPath} />
                    </Stack>
                    <TimeRemainingDescription
                      isFutureDate={isFutureDate}
                      formattedTargetDate={formattedTargetDate}
                      timeRemaining={timeRemaining}
                    />
                    <TimeCalculationDisplay
                      isFutureDate={isFutureDate}
                      formattedTargetDate={formattedTargetDate}
                      targetDate={targetDate}
                      showWeekend={showWeekend}
                      weekdays={weekdays}
                      weekends={weekends}
                    />
                    {/* <Box>
                  <Text>
                    {storeDescription({
                      formattedTargetDate,
                      isFutureDate,
                      timeRemaining,
                    })}
                  </Text>
                </Box> */}
                  </Box>
                  {/* <SimpleGrid columns={{ sm: 1, md: 2 }} spacing={10}>
                <Box pb={'20'}>
                  <Heading
                    as="h2"
                    fontSize={{ base: '24px', sm: '26px', md: '32px' }}
                  >
                    {' '}
                    Days from {formattedTargetDate}
                  </Heading>
                  {numDaysFrom.map(value => (
                    <Link
                      to={`/days-from/${value}-days-from-${urlPath.substring(
                        urlPath.length - 10
                      )}`}
                    >
                      <Text>
                        {value} days:{' '}
                        {`${dayjs(urlDate)
                          .add(value, 'day')
                          .format('MMMM DD, YYYY')}`}
                      </Text>
                    </Link>
                  ))}
                </Box>
                <Box pb={'20'}>
                  <Heading
                    as="h2"
                    fontSize={{ base: '24px', sm: '26px', md: '32px' }}
                  >
                    {' '}
                    Weeks from {formattedTargetDate}
                  </Heading>
                  {numWeeksFrom.map(value => (
                    <Link
                      to={`/days-from/${value}-days-from-${urlPath.substring(
                        urlPath.length - 10
                      )}`}
                    >
                      <Text>
                        {value} weeks:{' '}
                        {`${dayjs(urlDate)
                          .add(value, 'week')
                          .format('MMMM DD, YYYY')}`}
                      </Text>
                    </Link>
                  ))}
                </Box>
                <Box pb={'20'}>
                  <Heading
                    as="h2"
                    fontSize={{ base: '24px', sm: '26px', md: '32px' }}
                  >
                    {' '}
                    Months from {formattedTargetDate}
                  </Heading>
                  {numMonthsFrom.map(value => (
                    <Link
                      to={`/days-from/${value}-days-from-${urlPath.substring(
                        urlPath.length - 10
                      )}`}
                    >
                      <Text>
                        {value} months:{' '}
                        {`${dayjs(urlDate)
                          .add(value, 'month')
                          .format('MMMM DD, YYYY')}`}
                      </Text>
                    </Link>
                  ))}
                </Box>
                <Box pb={'20'}>
                  <Heading
                    as="h2"
                    fontSize={{ base: '24px', sm: '26px', md: '32px' }}
                  >
                    {' '}
                    Months from {formattedTargetDate}
                  </Heading>
                  {numMonthsFrom.map(value => (
                    <Link
                      to={`/days-from/${value}-days-from-${urlPath.substring(
                        urlPath.length - 10
                      )}`}
                    >
                      <Text>
                        {value} months:{' '}
                        {`${dayjs(urlDate)
                          .add(value, 'month')
                          .format('MMMM DD, YYYY')}`}
                      </Text>
                    </Link>
                  ))}
                </Box>
              </SimpleGrid> */}
                  <ThreeTierPricingHorizontal
                    currentDate={`${formattedTargetDate}`}
                    isFutureDate={isFutureDate}
                    targetDate={targetDate}
                  />
                </Container>
                <Footer />
              </>
            ) : (
              <Simmer />
            )}
          </>
        ) : (
          <>
            <InvalidPage />
          </>
        )}
      </Box>
    </>
  );
}
