import { Stack, Box, SimpleGrid, Text } from '@chakra-ui/react';
import React from 'react';
import dayjs from 'dayjs';
const TimeCalculationDisplay = ({
  isFutureDate,
  formattedTargetDate,
  targetDate,
  showWeekend,
  weekends,
  weekdays,
}) => {
  return (
    <Stack
      as={Box}
      textAlign={'center'}
      spacing={{ base: 8, md: 14 }}
      py={{ base: 1, md: 2 }}
      mb={'14'}
    >
      <SimpleGrid columns={{ sm: 1, md: 2 }} spacing={10}>
        <Stack
          as="Box"
          bg="green.500"
          color={'white'}
          borderRadius={'md'}
          py={{ base: 4, md: 6, lg: 18 }}
          px={{ base: 3, md: 5, lg: 10 }}
        >
          <Text
            fontSize={{
              base: '18px',
              sm: '20px',
              md: '22px',
              lg: '24px',
            }}
          >
            How many years {isFutureDate ? <>until </> : <>ago was </>}{' '}
            {formattedTargetDate}?
          </Text>
          <Text
            fontSize={{
              base: '2xl',
              sm: '4xl',
              md: '38px',
              lg: '46px',
            }}
          >
            {Math.abs(dayjs().diff(targetDate, 'years'))}
            <Text
              as={'span'}
              fontSize={{ base: '16px', sm: '18px', md: '22px' }}
            >
              {Math.abs(dayjs().diff(targetDate, 'years')) === 1 ? (
                <> Year</>
              ) : (
                <> Years</>
              )}
            </Text>
          </Text>
        </Stack>
        <Stack
          as="Box"
          bg="green.500"
          color={'white'}
          borderRadius={'md'}
          py={{ base: 4, md: 6, lg: 18 }}
          px={{ base: 3, md: 5, lg: 10 }}
        >
          <Text
            fontSize={{
              base: '18px',
              sm: '20px',
              md: '22px',
              lg: '24px',
            }}
          >
            How many months {isFutureDate ? <>until </> : <>ago was </>}{' '}
            {formattedTargetDate}?
          </Text>
          <Text
            fontSize={{
              base: '2xl',
              sm: '4xl',
              md: '38px',
              lg: '46px',
            }}
          >
            {Math.abs(dayjs().diff(targetDate, 'months'))}
            <Text
              as={'span'}
              fontSize={{ base: '16px', sm: '18px', md: '22px' }}
            >
              {Math.abs(dayjs().diff(targetDate, 'months')) === 1 ? (
                <> Month</>
              ) : (
                <> Months</>
              )}
            </Text>
          </Text>
        </Stack>
        <Stack
          as="Box"
          bg="green.500"
          // height="80px"
          color={'white'}
          borderRadius={'md'}
          py={{ base: 4, md: 6, lg: 18 }}
          px={{ base: 3, md: 5, lg: 10 }}
        >
          <Text
            fontSize={{
              base: '18px',
              sm: '20px',
              md: '22px',
              lg: '24px',
            }}
          >
            How many weeks {isFutureDate ? <>until </> : <>ago was </>}{' '}
            {formattedTargetDate}?
          </Text>
          <Text
            fontSize={{
              base: '2xl',
              sm: '4xl',
              md: '38px',
              lg: '46px',
            }}
          >
            {Math.abs(dayjs().diff(targetDate, 'weeks'))}
            <Text
              as={'span'}
              fontSize={{ base: '16px', sm: '18px', md: '22px' }}
            >
              {Math.abs(dayjs().diff(targetDate, 'weeks')) === 1 ? (
                <> Week</>
              ) : (
                <> Weeks</>
              )}
            </Text>
          </Text>
        </Stack>
        <Stack
          as="Box"
          bg="green.500"
          color={'white'}
          borderRadius={'md'}
          py={{ base: 4, md: 6, lg: 18 }}
          px={{ base: 3, md: 5, lg: 10 }}
        >
          <Text
            fontSize={{
              base: '18px',
              sm: '20px',
              md: '22px',
              lg: '24px',
            }}
          >
            How many days {isFutureDate ? <>until </> : <>ago was </>}{' '}
            {formattedTargetDate}?
          </Text>
          <Text
            fontSize={{
              base: '2xl',
              sm: '4xl',
              md: '38px',
              lg: '46px',
            }}
          >
            {Math.abs(dayjs().diff(targetDate, 'days'))}
            <Text
              as={'span'}
              fontSize={{ base: '16px', sm: '18px', md: '22px' }}
            >
              {Math.abs(dayjs().diff(targetDate, 'days')) === 1 ? (
                <> Day</>
              ) : (
                <> Days</>
              )}
            </Text>
          </Text>
        </Stack>
        <Stack
          as="Box"
          bg="green.500"
          // height="80px"
          color={'white'}
          borderRadius={'md'}
          py={{ base: 4, md: 6, lg: 18 }}
          px={{ base: 3, md: 5, lg: 10 }}
        >
          <Text
            fontSize={{
              base: '18px',
              sm: '20px',
              md: '22px',
              lg: '24px',
            }}
          >
            How many hours {isFutureDate ? <>until </> : <>ago was </>}{' '}
            {formattedTargetDate}?
          </Text>
          <Text
            fontSize={{
              base: '2xl',
              sm: '4xl',
              md: '38px',
              lg: '46px',
            }}
          >
            {Math.abs(dayjs().diff(targetDate, 'hours'))}
            <Text
              as={'span'}
              fontSize={{ base: '16px', sm: '18px', md: '22px' }}
            >
              {Math.abs(dayjs().diff(targetDate, 'hours')) !== 1 ? (
                <> Hours</>
              ) : (
                <> Hour</>
              )}
            </Text>
          </Text>
        </Stack>
        <Stack
          as="Box"
          bg="green.500"
          color={'white'}
          borderRadius={'md'}
          py={{ base: 4, md: 6, lg: 18 }}
          px={{ base: 3, md: 5, lg: 10 }}
        >
          <Text
            fontSize={{
              base: '18px',
              sm: '20px',
              md: '22px',
              lg: '24px',
            }}
          >
            How many minutes {isFutureDate ? <>until </> : <>ago was </>}{' '}
            {formattedTargetDate}?
          </Text>
          <Text
            fontSize={{
              base: '2xl',
              sm: '4xl',
              md: '38px',
              lg: '46px',
            }}
          >
            {Math.abs(dayjs().diff(targetDate, 'minutes'))}
            <Text
              as={'span'}
              fontSize={{ base: '16px', sm: '18px', md: '22px' }}
            >
              {Math.abs(dayjs().diff(targetDate, 'minutes')) !== 1 ? (
                <> Minutes</>
              ) : (
                <> Minute</>
              )}
            </Text>
          </Text>
        </Stack>
        <Stack
          as="Box"
          bg="green.500"
          color={'white'}
          borderRadius={'md'}
          py={{ base: 4, md: 6, lg: 18 }}
          px={{ base: 3, md: 5, lg: 10 }}
        >
          <Text
            fontSize={{
              base: '18px',
              sm: '20px',
              md: '22px',
              lg: '24px',
            }}
          >
            How many seconds {isFutureDate ? <>until </> : <>ago was </>}{' '}
            {formattedTargetDate}?
          </Text>
          <Text
            fontSize={{
              base: '2xl',
              sm: '4xl',
              md: '38px',
              lg: '46px',
            }}
          >
            {Math.abs(dayjs().diff(targetDate, 'seconds'))}
            <Text
              as={'span'}
              fontSize={{ base: '16px', sm: '18px', md: '22px' }}
            >
              {Math.abs(dayjs().diff(targetDate, 'seconds')) !== 1 ? (
                <> Seconds</>
              ) : (
                <> Second</>
              )}
            </Text>
          </Text>
        </Stack>
        {showWeekend && (
          <>
            <Stack
              as="Box"
              bg="green.500"
              // height="80px"
              color={'white'}
              borderRadius={'md'}
              py={{ base: 4, md: 6, lg: 18 }}
              px={{ base: 3, md: 5, lg: 10 }}
            >
              <Text
                fontSize={{
                  base: '18px',
                  sm: '20px',
                  md: '22px',
                  lg: '24px',
                }}
              >
                How many weekends {isFutureDate ? <>until </> : <>ago was </>}{' '}
                {formattedTargetDate}?
              </Text>
              <Text
                fontSize={{
                  base: '2xl',
                  sm: '4xl',
                  md: '38px',
                  lg: '46px',
                }}
              >
                {weekends}
                <Text
                  as={'span'}
                  fontSize={{
                    base: '14px',
                    sm: '18px',
                    md: '22px',
                  }}
                >
                  {weekends !== 1 ? <> Weekends</> : <> Weekend</>}
                </Text>
              </Text>
            </Stack>
            <Stack
              as="Box"
              bg="green.500"
              color={'white'}
              borderRadius={'md'}
              py={{ base: 4, md: 6, lg: 18 }}
              px={{ base: 3, md: 5, lg: 10 }}
            >
              <Text
                fontSize={{
                  base: '18px',
                  sm: '20px',
                  md: '22px',
                  lg: '24px',
                }}
              >
                How many weekdays {isFutureDate ? <>until </> : <>ago was </>}{' '}
                {formattedTargetDate}?
              </Text>
              <Text
                fontSize={{
                  base: '2xl',
                  sm: '4xl',
                  md: '38px',
                  lg: '46px',
                }}
              >
                {weekdays}
                <Text
                  as={'span'}
                  fontSize={{
                    base: '14px',
                    sm: '18px',
                    md: '22px',
                  }}
                >
                  {weekdays !== 1 ? <> Weekdays</> : <> Weekday</>}
                </Text>
              </Text>
            </Stack>
          </>
        )}
      </SimpleGrid>
    </Stack>
  );
};

export default TimeCalculationDisplay;
