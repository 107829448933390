import { Box, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import dayjs from 'dayjs';
import { getOrdinalIndicator } from './functions';

const TimeRemainingDescription = ({
  isFutureDate,
  formattedTargetDate,
  timeRemaining,
}) => {
  return (
    <Box>
      <Stack
        spacing={{ md: 10, xl: 16, sm: 10, base: 2 }}
        textAlign={'center'}
        justifyContent={'center'}
        pb={{ base: 8, md: 12 }}
      >
        <Text fontSize={{ base: '18px', sm: '22px', md: '24px' }}>
          {formattedTargetDate}{' '}
          {isFutureDate ? (
            <>is in </>
          ) : timeRemaining.days === 0 ? (
            <>is </>
          ) : (
            <>was </>
          )}
          {timeRemaining.years > 0 && (
            <>
              {timeRemaining.years}{' '}
              {timeRemaining.years === 1 ? <>year</> : <>years</>}
            </>
          )}
          {timeRemaining.months > 0 && (
            <>
              {timeRemaining.years !== 0 && <>,</>} {timeRemaining.months}{' '}
              {timeRemaining.months === 1 ? <>month</> : <>months</>}
            </>
          )}
          {timeRemaining.days > 0 && (
            <>
              {timeRemaining.months !== 0 || timeRemaining.years !== 0 ? (
                <>, and</>
              ) : null}{' '}
              {timeRemaining.days}{' '}
              {timeRemaining.days === 1 ? <>day</> : <>days</>}
            </>
          )}
          {timeRemaining.years === 0 &&
            timeRemaining.days === 0 &&
            timeRemaining.months === 0 &&
            !isFutureDate && <>today</>}
          {timeRemaining.years === 0 &&
            timeRemaining.days === 0 &&
            timeRemaining.months === 0 &&
            isFutureDate && <>tomorrow</>}
          {isFutureDate || timeRemaining.days === 0 ? null : <> ago</>}. It{' '}
          {isFutureDate ? <>will be</> : <>was</>} the{' '}
          {dayjs(formattedTargetDate).dayOfYear()}
          {getOrdinalIndicator(dayjs(formattedTargetDate).dayOfYear())} day of{' '}
          {formattedTargetDate.split(',')[1]} on{' '}
          {dayjs(formattedTargetDate).format('dddd')}, which{' '}
          {isFutureDate ? <>will be</> : <>was</>} a{' '}
          {dayjs(formattedTargetDate).weekday() === 0 ||
          dayjs(formattedTargetDate).weekday() === 6 ? (
            <>weekend</>
          ) : (
            <>weekday</>
          )}
          . It {isFutureDate ? <>will be</> : <>was</>} on the{' '}
          {dayjs(formattedTargetDate).week()}
          {getOrdinalIndicator(dayjs(formattedTargetDate).week())} week and{' '}
          {dayjs(formattedTargetDate).quarter()}
          {getOrdinalIndicator(dayjs(formattedTargetDate).quarter())} quarter of
          the
          {dayjs(formattedTargetDate).isLeapYear() ? (
            <> leap year (366 days).</>
          ) : (
            <> non-leap year (365 days).</>
          )}{' '}
        </Text>
      </Stack>
    </Box>
  );
};

export default TimeRemainingDescription;
