import {
  Box,
  Heading,
  Text,
  Button,
  Flex,
  Container,
  Stack,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { CloseIcon } from '@chakra-ui/icons';
import Nav from './navbar';
export default function InvalidDate() {
  const navigate = useNavigate();
  return (
    <>
      <Nav />
      <Container maxW="6xl">
        <Stack
          as={Box}
          textAlign="center"
          justifyItems={'center'}
          justifyContent={'center'}
          spacing={{ base: 8, md: 14 }}
          py={{ base: 20, md: 36 }}
        >
          <Box textAlign="center" py={10} px={6}>
            <Heading
              display="inline-block"
              as="h2"
              size="2xl"
              bgGradient="linear(to-r, green.400, green.600)"
              backgroundClip="text"
            >
              404
            </Heading>
            <Text fontSize="18px" mt={3} mb={2}>
              Page Not Found
            </Text>
            <Text color={'gray.500'} mb={6}>
              The page you're looking for does not seem to exist
            </Text>

            <Button
              colorScheme="green"
              bgGradient="linear(to-r, green.300, green.500, green.600)"
              color="white"
              variant="solid"
              onClick={() => navigate('/')}
            >
              Go to Home
            </Button>
          </Box>
        </Stack>
      </Container>
    </>
  );
}
