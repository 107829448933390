export const storeDescription = ({
  formattedTargetDate,
  isFutureDate,
  timeRemaining,
}) => {
  const content = `${formattedTargetDate}  ${
    isFutureDate ? 'is in' : timeRemaining.days === 0 ? 'is ' : 'was '
  }  ${
    timeRemaining.years > 0
      ? `${timeRemaining.years}
          ${timeRemaining.years === 1 ? 'year' : 'years'}`
      : ''
  } ${
    timeRemaining.months > 0
      ? `
          ${timeRemaining.years !== 0 ? ', ' : ''}
          ${timeRemaining.months}
          ${timeRemaining.months === 1 ? 'month' : 'months'}
        `
      : ''
  } ${
    timeRemaining.days > 0
      ? `
            ${
              timeRemaining.months !== 0 || timeRemaining.years !== 0
                ? ' and'
                : ''
            }
            ${timeRemaining.days}
            ${timeRemaining.days === 1 ? 'day' : 'days'}`
      : ''
  }${isFutureDate || timeRemaining.days === 0 ? '' : ' ago'}${
    timeRemaining.years === 0 &&
    timeRemaining.days === 0 &&
    timeRemaining.months === 0 &&
    !isFutureDate
      ? 'today'
      : ''
  }${
    timeRemaining.years === 0 &&
    timeRemaining.days === 0 &&
    timeRemaining.months === 0 &&
    isFutureDate
      ? 'tomorrow'
      : ''
  }. Find how many years, months, weeks, days, hours, minutes, and seconds ${
    isFutureDate ? 'until ' : 'since '
  } ${formattedTargetDate}.`;
  const trimmedContent = content.replace(/\s+/g, ' ');
  return trimmedContent;
};
