import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
function Meta({ pageTitle, pageDescription }) {
  pageTitle = pageTitle.replace(/\s+/g, ' ');
  pageDescription = pageDescription.replace(/\s+/g, ' ');
  const location = useLocation();
  return (
    <Helmet>
      <title>{pageTitle}</title>
      <meta name="title" content={pageTitle} />
      <meta name="description" content={pageDescription} />
      <meta name="twitter:title" content={pageTitle} />
      <meta name="twitter:site" content="HowLongism" />
      <meta name="twitter:description" content={pageDescription} />
      <meta
        name="twitter:url"
        content={`https://www.howlongism.com${location.pathname}`}
      />

      <meta name="twitter:domain" content="HowLongism.com" />
      <meta property="og:site_name" content="HowLongism" />
      <meta property="og:type" content="profile" />
      <meta
        property="og:url"
        content={`https://www.howlongism.com${location.pathname}`}
      />
      <meta name="og:title" content={pageTitle} />

      <meta property="og:description" content={pageDescription} />
      <base href="/app/" />
    </Helmet>
  );
}

export default Meta;
