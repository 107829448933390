import React from 'react';
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  FacebookShareButton,
  HatenaIcon,
  InstapaperIcon,
  LineIcon,
  LinkedinIcon,
  LivejournalIcon,
  MailruIcon,
  OKIcon,
  PinterestIcon,
  PinterestShareButton,
  PocketIcon,
  RedditIcon,
  RedditShareButton,
  TelegramIcon,
  TelegramShareButton,
  TumblrIcon,
  TwitterIcon,
  TwitterShareButton,
  ViberIcon,
  ViberShareButton,
  VKIcon,
  WeiboIcon,
  WhatsappIcon,
  WhatsappShareButton,
  LinkedinShareButton,
  WorkplaceIcon,
} from 'react-share';
import { Stack, SimpleGrid } from '@chakra-ui/react';
const ShareButtons = ({ urlToShare }) => {
  const shareUrl = `https://howlongism.com${urlToShare}`;
  return (
    <Stack
      direction={'row'}
      alignSelf={'center'}
      // spacing={3}
      justifyContent={'center'}
      position={'relative'}
    >
      <SimpleGrid columns={{ base: 5, md: 10 }} spacing={2}>
        <EmailShareButton url={shareUrl}>
          <EmailIcon size={40} borderRadius={'5'} />
        </EmailShareButton>
        <FacebookShareButton url={shareUrl}>
          <FacebookIcon size={40} borderRadius={'5'} />
        </FacebookShareButton>
        <TwitterShareButton url={shareUrl}>
          <TwitterIcon size={40} borderRadius={'5'} />
        </TwitterShareButton>
        <WhatsappShareButton url={shareUrl}>
          <WhatsappIcon size={40} borderRadius={'5'} />
        </WhatsappShareButton>
        <ViberShareButton url={shareUrl}>
          <ViberIcon size={40} borderRadius={'5'} />
        </ViberShareButton>
        <TelegramShareButton url={shareUrl}>
          <TelegramIcon size={40} borderRadius={'5'} />
        </TelegramShareButton>
        <FacebookMessengerShareButton url={shareUrl}>
          <FacebookMessengerIcon size={40} borderRadius={'5'} />
        </FacebookMessengerShareButton>
        <RedditShareButton url={shareUrl}>
          <RedditIcon size={40} borderRadius={'5'} />
        </RedditShareButton>
        <PinterestShareButton url={shareUrl}>
          <PinterestIcon size={40} borderRadius={'5'} />
        </PinterestShareButton>
        <LinkedinShareButton url={shareUrl}>
          <LinkedinIcon size={40} borderRadius={'5'} />
        </LinkedinShareButton>
      </SimpleGrid>
    </Stack>
  );
};

export default ShareButtons;
