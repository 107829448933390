import React, { useEffect, useState } from 'react';
import {
  Box,
  Text,
  Button,
  Input,
  Container,
  Stack,
  Heading,
  Flex,
  InputGroup,
  InputRightAddon,
  SimpleGrid,
  Spacer,
  useToast,
  chakra,
  useColorModeValue,
} from '@chakra-ui/react';
import Nav from '../components/navbar';
import { useLocation, useNavigate } from 'react-router-dom';

import dayjs from 'dayjs';
import Simmer from '../components/simmer';
import Footer from '../components/footer';
import Meta from '../components/Meta';

import {
  calculateWeekdaysAndWeekends,
  checkValidDate,
  getOrdinalIndicator,
} from '../components/functions';
import ShareButtons from '../components/shareButtons';
import TimeRemainingDescription from '../components/timeRemainingDescription';
import TimeCalculationDisplay from '../components/TimeCalculationDisplay';
import ThreeTierPricingHorizontal from './test';

var weekOfYear = require('dayjs/plugin/weekOfYear');
var weekday = require('dayjs/plugin/weekday');
var isLeapYear = require('dayjs/plugin/isLeapYear');
var dayOfYear = require('dayjs/plugin/dayOfYear');
var quarterOfYear = require('dayjs/plugin/quarterOfYear');
var calendar = require('dayjs/plugin/calendar');
var duration = require('dayjs/plugin/duration');
dayjs.extend(duration);
dayjs.extend(calendar);
dayjs.extend(dayOfYear);
dayjs.extend(isLeapYear);
dayjs.extend(weekday);
dayjs.extend(weekOfYear);
dayjs.extend(quarterOfYear);
export const DaysFrom = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const pathname = location.pathname.split('/');
  const value = pathname[2].split('-')[0];
  const urlPath = location.pathname;

  const toast = useToast();

  const options = {
    // weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };
  //
  const splitedHiphenVlaue = urlPath.split('/')[2].split('-');
  const urlDate = dayjs(
    `${splitedHiphenVlaue[splitedHiphenVlaue.length - 3]}-${
      splitedHiphenVlaue[splitedHiphenVlaue.length - 2]
    }-${splitedHiphenVlaue[splitedHiphenVlaue.length - 1]}`
  ).$d;

  const [targetDate, setTargetDate] = useState(
    dayjs(`${urlDate}`).add(value, 'day').$d
  );
  const [changedDate, setChangedDate] = useState(
    dayjs(targetDate).format('YYYY-MM-DD')
  );

  const inputDefaultValue = dayjs(targetDate).format('YYYY-MM-DD');
  const [formattedTargetDate, setFormattedTargetDate] = useState();

  // Declare a state variable for the time remaining
  const [timeRemaining, setTimeRemaining] = useState(null);
  const [isFutureDate, setIsFutureDate] = useState();
  const [weekends, setWeekends] = useState();
  const [weekdays, setWeekdays] = useState();
  const [showWeekend, setShowWeekend] = useState(null);
  const [isValidDate, setValidDate] = useState();
  // const convertedDate = urlDate.setHours(0, 0, 0, 0);

  const calculateTimeRemaining = () => {
    // Get the current time
    const currentTime = dayjs().$d;

    // Calculate the time remaining
    let timeDifference;
    if (targetDate > currentTime) {
      // The target date is in the future
      timeDifference = targetDate - currentTime;
      setIsFutureDate(true);
    } else {
      // The target date is in the past
      timeDifference = currentTime - targetDate;
      setIsFutureDate(false);
    }

    timeDifference = dayjs(targetDate).diff(dayjs());

    const years = Math.abs(dayjs.duration(timeDifference).years());
    const months = Math.abs(dayjs.duration(timeDifference).months());
    const days = Math.abs(dayjs.duration(timeDifference).days());
    const hours = Math.abs(dayjs.duration(timeDifference).hours());
    const minutes = Math.abs(dayjs.duration(timeDifference).minutes());
    const seconds = Math.abs(dayjs.duration(timeDifference).seconds());
    const weeks = Math.abs(dayjs.duration(timeDifference).weeks());

    if (Math.abs(dayjs.duration(timeDifference).years()) <= 200) {
      setShowWeekend(true);
      const weekdaysAndWeekends = calculateWeekdaysAndWeekends(
        currentTime,
        targetDate
      );
      const weekdays = weekdaysAndWeekends.weekday;
      const weekends = weekdaysAndWeekends.weekend;
      setWeekdays(weekdays);
      setWeekends(weekends);
    }

    // Update the timeRemaining state variable
    setTimeRemaining({
      years,
      months,
      days,
      hours,
      minutes,
      seconds,
    });
  };

  const parameter = () => {
    if (changedDate !== '') {
      setTargetDate(dayjs(`${changedDate}`).$d);

      navigate(`/date/${changedDate}`);
    } else {
      toast({
        position: 'top-right',
        title: 'Please select the date',
        // description: 'Please select the date',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    const calculatedDate = dayjs(`${urlDate}`).add(value, 'day');
    if (checkValidDate(calculatedDate)) {
      setValidDate(true);

      setFormattedTargetDate(
        dayjs(`${urlDate}`).add(value, 'day').format('MMMM DD, YYYY')
      );
      const interval = setInterval(calculateTimeRemaining, 1000);
      return () => clearInterval(interval);
    } else {
      navigate('/not-found');
    }
  }, [urlDate]);

  const storeDescription = ({
    value,
    urlDate,
    formattedTargetDate,
    isFutureDate,
    timeRemaining,
  }) => {
    const content = `${value} ${value === 1 ? 'day' : 'days'} from ${dayjs(
      urlDate
    ).format(
      'MMMM DD, YYYY'
    )} is ${formattedTargetDate}. Find how many years, months, weeks, days, hours, minutes, and seconds ${
      isFutureDate ? 'until ' : 'since '
    } ${formattedTargetDate}.`;
    return content;
  };

  return (
    <Box>
      <Nav />
      {timeRemaining ? (
        <>
          <Meta
            pageTitle={`What date ${isFutureDate ? 'is' : 'was'} ${value}
              ${parseInt(value) === 1 ? 'day' : 'days'} from
              ${dayjs(urlDate).format('MMMM DD, YYYY')}?`}
            pageDescription={storeDescription({
              value,
              urlDate,

              formattedTargetDate,
              isFutureDate,
              timeRemaining,
            })}
          />
          <Container maxW="6xl">
            <Stack as={Box} textAlign={'center'} pt={{ base: 10, md: 20 }}>
              <Heading
                as="h1"
                fontWeight={600}
                fontSize={{ base: '2xl', sm: '4xl', md: '6xl' }}
                id="page-description"
              >
                What date {isFutureDate ? <>is</> : <>was</>} {value}{' '}
                {parseInt(value) === 1 ? <>day </> : <>days </>} from{' '}
                {dayjs(urlDate).format('MMMM DD, YYYY')}?
              </Heading>
              <Stack
                as={Box}
                textAlign={'center'}
                py={{ base: 4, md: 10 }}
                color={'green.500'}
              >
                <Text fontSize={{ base: '1xl', sm: '2xl', md: '3xl' }}>
                  {value} {value == 1 ? <>day </> : <>days</>} from{' '}
                  {dayjs(urlDate).format('MMMM DD, YYYY')} is
                </Text>
                <Text
                  fontWeight={600}
                  fontSize={{ base: '2xl', sm: '3xl', md: '5xl' }}
                >
                  {dayjs(urlDate).add(value, 'day').format('dddd')},{' '}
                  {dayjs(urlDate).add(value, 'day').format('MMMM DD, YYYY')}
                </Text>
                {/* <Text
                  fontWeight={600}
                  fontSize={{ base: '2xl', sm: '3xl', md: '5xl' }}
                >
                </Text> */}
              </Stack>
            </Stack>

            <Box>
              <Stack
                as={Box}
                textAlign={'center'}
                spacing={{ base: 10, md: 14 }}
                pt={{ base: 10, md: 12 }}
                pb={{ base: 10, md: 12 }}
              >
                <Heading
                  as="p"
                  fontWeight={600}
                  fontSize={{ base: '2xl', sm: '4xl', md: '6xl' }}
                  id="page-description"
                >
                  Time{' '}
                  {isFutureDate ? <>Remaining Till </> : <>Passed Since </>}
                  {formattedTargetDate}:
                </Heading>
                <Text
                  fontWeight={600}
                  fontSize={{ base: '2xl', sm: '3xl', md: '5xl' }}
                  lineHeight={'150%'}
                >
                  {targetDate.toLocaleDateString('en-us', options)}
                  <Text as={'span'}>
                    {isFutureDate ? <> is in</> : <> was</>}
                  </Text>
                  <Spacer />
                </Text>

                <Stack
                  direction={'row'}
                  spacing={{ md: 10, xl: 16, sm: 10, base: 2 }}
                  textAlign={'center'}
                  justifyContent={'center'}
                  fontWeight={'600'}
                  color={'green.500'}
                  //   align={'center'}
                  //   alignSelf={'center'}
                  //   position={'relative'}
                >
                  <Flex direction={'column'}>
                    <Text fontSize={{ base: '2xl', sm: '3xl', md: '7xl' }}>
                      {timeRemaining.years}
                    </Text>
                    <Text
                      fontSize={{ base: '16px', sm: '18px', md: '26px' }}
                      fontWeight={400}
                    >
                      {timeRemaining.years === 1 ? <>Year</> : <>Years</>}
                    </Text>
                  </Flex>
                  <Flex direction={'column'}>
                    <Text fontSize={{ base: '2xl', sm: '3xl', md: '7xl' }}>
                      {timeRemaining.months}
                    </Text>
                    <Text
                      fontSize={{ base: '16px', sm: '18px', md: '26px' }}
                      fontWeight={400}
                    >
                      {timeRemaining.months === 1 ? <>Month</> : <>Months</>}
                    </Text>
                  </Flex>
                  <Flex direction={'column'}>
                    <Text fontSize={{ base: '2xl', sm: '3xl', md: '7xl' }}>
                      {timeRemaining.days}
                    </Text>
                    <Text
                      fontSize={{ base: '16px', sm: '18px', md: '26px' }}
                      fontWeight={400}
                    >
                      {timeRemaining.days === 1 ? <>Day</> : <>Days</>}
                    </Text>
                  </Flex>
                  <Flex direction={'column'}>
                    <Text fontSize={{ base: '2xl', sm: '3xl', md: '7xl' }}>
                      {timeRemaining.hours}
                    </Text>
                    <Text
                      fontSize={{ base: '16px', sm: '18px', md: '26px' }}
                      fontWeight={400}
                    >
                      {timeRemaining.hours === 1 ? <>Hour</> : <>Hours</>}
                    </Text>
                  </Flex>
                  <Flex direction={'column'}>
                    <Text fontSize={{ base: '2xl', sm: '3xl', md: '7xl' }}>
                      {timeRemaining.minutes}
                    </Text>
                    <Text
                      fontSize={{ base: '16px', sm: '18px', md: '26px' }}
                      fontWeight={400}
                    >
                      {timeRemaining.minutes === 1 ? <>Minute</> : <>Minutes</>}
                    </Text>
                  </Flex>
                  <Flex direction={'column'}>
                    <Text fontSize={{ base: '2xl', sm: '3xl', md: '7xl' }}>
                      {timeRemaining.seconds}
                    </Text>
                    <Text
                      fontSize={{ base: '16px', sm: '18px', md: '26px' }}
                      fontWeight={400}
                    >
                      {timeRemaining.seconds === 1 ? <>Second</> : <>Seconds</>}
                    </Text>
                  </Flex>
                  {/* {timeRemaining.hours} hours,{' '}
                        {timeRemaining.minutes} minutes, {timeRemaining.seconds}{' '}
                        seconds */}
                </Stack>
                <Stack
                  direction={'Column'}
                  alignSelf={'center'}
                  spacing={3}
                  justifyContent={'center'}
                  position={'relative'}
                >
                  <Text alignSelf={'start'} pb={2} fontSize={{ md: '18px' }}>
                    Try Using Another Date:
                  </Text>

                  <InputGroup>
                    <Input
                      type="date"
                      defaultValue={inputDefaultValue}
                      placeholder="Select Date and Time"
                      onChange={e => setChangedDate(e.target.value)}
                    />
                    <InputRightAddon
                      children="Go"
                      colorScheme={'green'}
                      color={'white'}
                      bg={'green.400'}
                      px={6}
                      cursor={'pointer'}
                      _hover={{
                        bg: 'green.500',
                      }}
                      onClick={() => parameter()}
                    />
                  </InputGroup>
                </Stack>
                <ShareButtons urlToShare={urlPath} />
              </Stack>
              <TimeRemainingDescription
                isFutureDate={isFutureDate}
                formattedTargetDate={formattedTargetDate}
                timeRemaining={timeRemaining}
              />
              <TimeCalculationDisplay
                isFutureDate={isFutureDate}
                formattedTargetDate={formattedTargetDate}
                targetDate={targetDate}
                showWeekend={showWeekend}
                weekdays={weekdays}
                weekends={weekends}
              />
            </Box>
            <ThreeTierPricingHorizontal
              currentDate={`${formattedTargetDate}`}
              isFutureDate={isFutureDate}
              targetDate={targetDate}
            />
          </Container>
          <Footer />
        </>
      ) : (
        <Simmer />
      )}
    </Box>
  );
};
