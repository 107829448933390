import React, { useState } from 'react';

import {
  Box,
  Text,
  Button,
  Input,
  Container,
  Stack,
  Heading,
  useToast,
} from '@chakra-ui/react';
import Nav from './navbar';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';

const InvalidPage = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const [targetDate, setTargetDate] = useState();
  const [changedDate, setChangedDate] = useState(
    dayjs(targetDate).format('YYYY-MM-DD')
  );
  const [inputDefaultValue, setInputDefaultValue] = useState(
    dayjs().format('YYYY-MM-DD')
  );

  const parameter = () => {
    if (changedDate !== '') {
      // const currentTime = new Date();
      setTargetDate(dayjs(`${changedDate}`).$d);

      navigate(`/date/${changedDate}`);
    } else {
      toast({
        position: 'top-right',
        title: 'Please select the date',
        // description: 'Please select the date',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Stack
        textAlign={'center'}
        justifyContent={'center'}
        alignItems={'center'}
        spacing={{ base: 10, md: 14 }}
        py={{ base: 10, md: 28 }}
      >
        <Box textAlign="center" py={10} px={6}>
          <Heading
            display="inline-block"
            as="h2"
            size="2xl"
            bgGradient="linear(to-r, green.400, green.600)"
            backgroundClip="text"
          >
            404
          </Heading>
          <Text fontSize="18px" mt={3} mb={2}>
            Date Not Found
          </Text>
          <Text color={'gray.500'} mb={6}>
            The date you are looking for does not seem to exist
          </Text>

          {/* <Text alignSelf={'start'} pb={2} fontSize={{ md: '16px' }}>
              Try Using Another Date:
            </Text>

            <InputGroup>
              <Input
                type="date"
                placeholder="Select Date and Time"
                defaultValue={inputDefaultValue}
                onChange={e => setChangedDate(e.target.value)}
              />
              <InputRightAddon
                children="Go"
                colorScheme={'green'}
                color={'white'}
                bg={'green.400'}
                px={6}
                cursor={'pointer'}
                _hover={{
                  bg: 'green.500',
                }}
                onClick={() => parameter()}
              />
            </InputGroup> */}
          {/* <Text fontSize="16px" mt={3} mb={2}>
            -OR-
          </Text> */}
          <Button
            colorScheme="green"
            bgGradient="linear(to-r, green.300, green.500, green.600)"
            color="white"
            variant="solid"
            onClick={() => navigate('/')}
          >
            Go to Home Page
          </Button>
        </Box>
      </Stack>
    </>
  );
};

export default InvalidPage;
